p { margin-bottom: 0; }

.message{
    font-size: small;
}

.AlertContent {
  display: flex;
  flex-direction: row;
}

.AlertBody {
  flex: 1;
}
// cursor on hover
.AlertCloseIcon:hover {
  cursor: pointer;
}

//Grid container for the component with 12 columns and 2 rows
 /* .AlertHeader {
    display: grid;
    grid-template-columns: repeat( 12, 1fr );
    grid-template-rows: 5vh;
    grid-gap: 0vw 0;
    border: none;
    padding-bottom: .3rem;
    padding-top: .3rem;
    
        display: flex;
        justify-content: space-between;
  }*/
  
  

 /* //Class for the title of the component
  .AlertTitle {
    grid-column: 1;
    grid-row: 1 ;
  }
  
  //Class for the filters of the component
  .AlertCloseButton {
    grid-column: 8;
    grid-row: 1;
    margin-left: 10px;
  }*/