@import '~@alaskaairux/design-tokens/dist/tokens/SCSSVariables';

.username-popup {
	position: absolute;
	//top: 30px; /* Adjust the top position as needed */
	right: 1rem;
	box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2);
	border-radius: 5px; /* Rounded corners */
	font-family: Arial, sans-serif;
	background-color: white;
	border: 1px solid #ccc;
	padding: 10px;
	z-index: 1000;
	display: flex;
	flex-direction: row;
  }

  .UserPopupBody {
	padding-top: 8%;
	flex: 1;
	//margin-bottom: 10px;
	margin:0; 
  }
  
.username{
font-size: medium;
font-weight: bold;
border-bottom: 1px solid lightgray;
}

.adgroup{
font-size: small;
//border-bottom: 1px solid lightgray;
}

.userIconHeader{
align-items: right;
}
  
  .username,
.adgroup {
  display: block; /* Display each line on a separate block */
  margin-bottom: 5px; /* Add some vertical spacing between lines */
  color: #01426a;
}

.UserCloseIcon:hover{
	cursor: pointer;
}

.Titlebar {
    display: grid;
    padding: 0 4rem;
    //background: #01426a;
    box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.08);
    height: 5rem;
    border-bottom: 1px solid rgba(0, 39, 74, 0.10);
    padding-bottom: 5rem;  
	grid-template-columns: repeat(3, 1fr);
	grid-template-rows: 100%;  
  }
  .item1 {
	grid-column: 1/7;
	grid-row: 1 ;
	}
	
.item2 {
	grid-column: 7/10;
	grid-row: 1;
	margin-top: auto;
	align-items: left;
	}

@media (max-width: 596px) {
.HeaderClass
{
    font-size: 0.5em;
    align-items: left; 
    justify-content: left;
    color: #01426a;
	vertical-align: middle;
	padding-top: 100px;
}
}

.HeaderClass
{
    font-size: 0.8em;
    align-items: left; 
    justify-content: left;
    color: #01426a;
	vertical-align: middle;
}

img {
    width: 24px;
    //To change the icon color, change the filter property: 
    filter: invert(18%) sepia(100%) saturate(1240%) hue-rotate(193deg) brightness(93%) contrast(102%);
}

.icon {
    position: relative;
  }

.icon:after {
position: absolute;
content: '';
width: 24px;
height: 24px;
background-color: #01426a;
-webkit-mask-image: url('https://unpkg.com/@alaskaairux/icons@latest/dist/icons/interface/navigation-menu.svg');
mask-image: url('https://unpkg.com/@alaskaairux/icons@latest/dist/icons/interface/navigation-menu.svg');
}

/* sidebar Nav */

.userHeader{
	//display: inline-block;
    width: 40px; // Adjust the size as needed
    height: 40px; 
	background-color: white; // Blue color
    border-radius: 50%; // Rounded corners to make it a circle
	text-align: center;
    line-height: 40px; // Vertically center the text
	cursor: pointer;
	margin: 20px;
	border: 2px solid #01426a;
	color: #01426a;
	display: flex;
  align-items: center;
  justify-content: center;
}

header {
	height: 5rem;
	color: #01426a;
	display: flex;
	align-items: left;
	justify-content: left;
	align-content: left;
}
header > h1 {
	width: 100%;
	text-align: left;
	color: #01426a;
	top: 2rem;
}
header > .top {
	position: absolute;
	left: 1rem;
	top: 2rem;
}
header > .top a.menu_icon i {
	color: #01426a;
	font-size: 5rem;
	padding-top:0 5px;
	transition: .2s ease;
}
header > .top a.menu_icon:hover i {
	color: #494949;
}
nav.menu {
	width: 300px;
	min-height: calc(100vh - 5rem); //5rem is the height of the Titlebar class
	background-color: #eef2f5;
    position: absolute;
    top: 5rem;
	left: -300px;
	transition: .3s all;
    z-index: 100;
}
nav.menu > a {
    display: block;
    padding: 5px;
    margin: 15px 0 0px 20px;
    color: #01426a;

}

.menu_show {
	left: 0!important;
    width: 100%;
}

@media screen and (max-width: 425px) {
	header h3 {
		font-size: 16px;
	}
}
@media screen and (max-width: 360px) {
	nav.menu {
		width: 100%;
		left: -100%; 
	}
}

