@import '~@alaskaairux/design-tokens/dist/tokens/SCSSVariables';
@import "~@aurodesignsystem/webcorestylesheets/dist/breakpoints";
@import '~@aurodesignsystem/webcorestylesheets/dist/fonts';
@import "~@aurodesignsystem/webcorestylesheets/dist/normalize";
@import "~@aurodesignsystem/webcorestylesheets/dist/essentials";
@import "~@aurodesignsystem/webcorestylesheets/dist/utilityClasses";
@import "~@aurodesignsystem/webcorestylesheets/dist/componentSupport/table";

//Layout

body {
  height: 100vh;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root {
  height: 100vh; // 100% of the viewport height on root element
  display: flex;
  flex-direction: column;
}

//Class to make the component fill the remaining space
.ComponentContent {
  flex: 1; //Fill the remaining space
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

//Grid container for the component with 12 columns and 2 rows
.ComponentHeader {
  display: grid;
  grid-template-columns: repeat( 12, 1fr );
  grid-template-rows: 10vh;
  grid-gap: 0vw 0;
  border: none;
  border-bottom: 1px solid gray;
  padding-bottom: .3rem;
  padding-top: .3rem;
}

//Class for the title of the component
.ComponentHeaderTitle {
  grid-column: 1/7;
  grid-row: 1 ;
}

//Class for the filters of the component
.ComponentHeaderFilters {
  grid-column: 7/10;
  grid-row: 1;
  margin-top: auto;
}

//Class for the actions buttons of the component header
.ComponentHeaderActions {
  grid-column: 10/span 3;
  grid-row: 1;
  margin: auto 15px auto auto;
  vertical-align: middle;
}
//Class for the second row of the component header
.ComponentHeaderSecondRow {
  grid-column: 1/span 12;
  grid-row: 2;
}

.ComponentContent {
  flex: 1;
  height: 100%;
  overflow: auto;
  padding-left: .3rem;
  padding-right: .3rem;
}

.ComponetAlerts{
	transition: .3s all;
}

.grid-container {  
  display: grid;
  grid-template-columns: repeat( 12, 1fr );
  grid-template-rows:10vh 70vh;
  grid-gap: 0vw 0;
  margin-left: 1%;
  margin-right: 1%;
}

//Font and colors

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


//Auro table styles

.auro_table thead {
  position: sticky;
  top: 0;
  z-index: 1;
 }

.auro-table
{
 font-size: 100%;
}

.TableHeader {
  font-weight: bold;
  background-color: white;  
}

.TableHeaderContent{
  display: flex;
}

.TableHeaderContentSVG div svg {  
  min-width: 1rem !important;
  height: 1rem !important;
}

.TableHeaderContent:hover {
  cursor: pointer;  
}

.auro_table td, .auro_table th {
  padding: .3rem !important;
}

.auro_table {
  font-weight: 400;    
}

//Auro input style for counter
.inputCounter {
  top: 5px;
  right: 10px;
  position: absolute; 
}