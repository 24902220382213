@keyframes spinner {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  .loading-spinner {
    width: 50px;
    height: 50px;
    border: 10px solid #f3f3f3; /* Light grey */
    border-top: 10px solid #383636; /* Black */
    border-radius: 50%;
    animation: spinner 1.5s linear infinite;
    position: absolute;
    top: 50%;
    left: 50%;
    
    -moz-transform: translateX(-50%) translateY(-50%);
    -webkit-transform: translateX(-50%) translateY(-50%);
    transform: translateX(-50%) translateY(-50%);
  }
  
  .overlay {
    height: 100vh;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.286);
    z-index: 10;
    top: 0;
    left: 0;
    position: fixed;
  }