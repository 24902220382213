.FilterRulesHeader {
    font-size: 0.6em;
    align-items: center; 
    justify-content: center;
    color: #01426a;
	vertical-align: middle;
    margin-left: 10%;
}

@media (max-width: 780px) {
    .FilterRulesHeader
    {
        font-size: 17px;
        margin-left: 1%;
    }
}