#notauthorized {
  position: relative;
  height: 60vh;
}

  #notauthorized .notauthorized {
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
  }

.notauthorized {
  max-width: 520px;
  width: 100%;
  line-height: 1.4;
  text-align: center;
}

  .notauthorized .notauthorized-401 {
    position: relative;
    height: 100px;
    margin: 0px auto 20px;
    z-index: -1;
  }
