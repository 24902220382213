
.formfooter {
    float: right;
    margin-right: 20px;
    margin-top: 30px;
}

.auro_table th, .auro_table td {
   padding: 0.7rem;
}

.centerColumn {
    text-align: center;
}

.placeholder {
    color: #7b7b7b;
}

.option {
    color: black;
}

#fieldNameSelect {
    width: 300px;
    font-size: 1rem;
    border-radius: 5px;
    border: 1px solid var(--auro-color-border-primary-on-light);
    min-height: calc(var(--auro-size-700, 56px) + 2px);
    max-height: calc(var(--auro-size-700, 56px) + 2px);
    margin-bottom: 4px;          
}

#fieldNameSelect:focus {
    border: 2px solid var(--auro-color-ui-default-on-light);
}

#fieldValue, #fiterRuleDescription {
    width: 250px;
}