
::part(dialog) {       
    padding-right: 20px;
    padding-left: 20px;    
}

::part(dialog-header){
    padding-top: 35px;
}

@media screen and (min-width: 1024px)
{
    ::part(dialog) {       
        max-height: 89%;
    }
}